/**
 * Created by huzeyin on 2020/5/12.
 */
import request from '../utils/request'


export function loginByStudent (student_email,student_pwd) {
  return request.post('./index.php', {
    function: 'loginByStudent',
    student_email,
    student_pwd
  })
}

export function loginByEducator (educator_email,educator_pwd) {
  return request.post('./index.php', {
    function: 'loginByEducator',
    educator_email,
    educator_pwd
  })
}

export function listActiveRooms () {
  return request.post('./index.php', {
    function: 'listActiveRooms'
  })
}


export function createRoomToken (roomId,userId) {
  return request.post('./index.php', {
    function: 'createRoomToken',
    roomId,userId
  })
}

export function createPresentationRoomToken (roomId,userId) {
  return request.post('./index.php', {
    function: 'createPresentationRoomToken',
    roomId,userId
  })
}

export function listRoomUsers (room_id) {
  return request.post('./index.php', {
    function: 'listRoomUsers',
    room_id
  })
}
//新建或者更新卡片
export function updateCard (card_id,sector,name,intro) {
  return request.post('./index.php', {
    function: 'updateCard',
    card_id,
    sector,
    name,
    intro
  })
}

export function getPresentationInfo(student_id,type) {
  return request.post('./index.php', {
    function: 'getPresentationInfo',
    student_id,
    type
  })
}
export function getAllRooms () {
  return request.post('./index.php', {
    function: 'getAllRooms'
  })
}
export function getRoom (id) {
  return request.post('./index.php', {
    function: 'getRoom',
    id
  })
}
export function kickUser (room_id,userId) {
  return request.post('./index.php', {
    function: 'kickUser',
    room_id,
    userId
  })
}

export function changeRoomStatus (status,id) {
  return request.post('./index.php', {
    function: 'changeRoomStatus',
    status,
    id
  })
}

export function findStudentByEmail (student_email) {
  return request.post('./index.php', {
    function: 'findStudentByEmail',
    student_email
  })
}

export function addStudentToRoom (room_id,student_id) {
  return request.post('./index.php', {
    function: 'addStudentToRoom',
    room_id,student_id
  })
}

export function removeStudentInRoom (room_id,student_id) {
  return request.post('./index.php', {
    function: 'removeStudentInRoom',
    room_id,student_id
  })
}

export function getRoomStudents (room_id) {
  return request.post('./index.php', {
    function: 'getRoomStudents',
    room_id
  })
}

export function updateRoom (id,room_id,room_type) {
  return request.post('./index.php', {
    function: 'updateRoom',
    id,room_id,room_type
  })
}
export function getStudent (student_id) {
  return request.post('./index.php', {
    function: 'getStudent',
    student_id
  })
}

export function getMyEvents (student_id) {
  return request.post('./index.php', {
    function: 'getMyEvents',
    student_id
  })
}

export function checkEventOpen (student_id,project_id,event) {
  return request.post('./index.php',{
    function: 'checkEventOpen',
    student_id,project_id,event
  })
}

export function getApplicationsByEvents (room_id,room,start_time) {
  return request.post('./index.php',{
    function: 'getApplicationsByEvents',
    room_id,room,start_time
  })
}


export function getMyRoomStatus (application_id,event,type,room_id) {
  return request.post('./index.php',{
    function: 'getMyRoomStatus',
    application_id,event,type,room_id
  })
}

export function getApplicationsAdmin (room,room_activity) {
  return request.post('./index.php',{
    function: 'getApplicationsAdmin',
    room,room_activity
  })
}

export function getPresentationRoomsByApplication (student_id) {
  return request.post('./index.php',{
    function: 'getPresentationRoomsByApplication',
    student_id
  })
}



