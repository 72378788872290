<template>
  <div id="app" style="height:100%">

      <router-view />

  </div>
</template>
<script>
export default {
  components: {

  },
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>
